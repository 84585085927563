@defer (on idle) {
    @if (similarVehicles().length > 0) {
        <div class="relative flex w-full flex-col overflow-x-hidden pb-[70px] pl-24 pt-48 lg:items-center">
            <div class="absolute bottom-0 left-0 h-[40%] w-full bg-grey-500"></div>
            <h3 class="leading-29 mb-32 text-24 lg:mb-64 lg:text-34 lg:leading-41">{{ title() }}</h3>

            <div class="flex w-full overflow-visible lg:justify-center xl:justify-end" #container>
                <ngx-px-carousel-awesome-plus
                    class="-ml-12 mr-12 block lg:ml-0 xl:w-[1197px]"
                    [arrowPrevTemplate]="prevArrow"
                    [arrowNextTemplate]="nextArrow"
                    centered="false"
                    forceCenter="false"
                >
                    @for (vehicle of similarVehicles(); track $index) {
                        <div
                            class="mx-12 !max-w-[342px] lg:!max-w-[379px]"
                            *ngxPxCarouselItem
                            [style.width]="container.getBoundingClientRect().width + 'px'"
                        >
                            <lib-vehicle-card
                                class="block h-full shadow-[0px_4px_20.6px_0px_rgba(0,_0,_0,_0.11)]"
                                [vehicle]="vehicle"
                            />
                        </div>
                    }
                </ngx-px-carousel-awesome-plus>
            </div>

            <ng-content select="[link]" />
        </div>
    }
}

<ng-template #nextArrow>
    <i class="icon-chevron-right !hidden text-[56px] text-grey-300 md:!flex" (click)="$event.preventDefault()"></i>
</ng-template>

<ng-template #prevArrow>
    <i class="icon-chevron-left !hidden text-[56px] text-grey-300 md:!flex" (click)="$event.preventDefault()"></i>
</ng-template>
